import { RouteInfo } from './sidebar.metadata';

export enum mainFeatures{
  dashboard = "dashboard",
  dashboardSuperAdmin = "dashboardSuperAdmin",
  buildings = "buildings",
  buildingsAdmin = "buildingsAdmin",
  notices = "notices",
  releases = "releases",
  areas = "areas",
  reservations = "reservations",
  visits = "visits",
  registrations = "registrations",
  communities = "comunities",
  services = "services",
  accountStatements = "accountStatements",
  chats = "chats",
  frequentAccess = "frequentAccess",
  administration = "administration",
  administrativeDropdown = "administrativeDropdown",
  users = "users",
  usersSuperAdmin = "usersSuperAdmin",
  units = "units",
  pqrs = "pqrs",
  doorOpening = "doorOpening",
  accessManagement = "accessManagement",
  securityCenters = "securityCenters",
  munilyPay = "munilyPay",
  munilyPayTransactions = "munilyPayTransactions",
  controlAccess = "controlAccess",
  doorOpeningAdmin = "doorOpeningAdmin",
  munilyProviders = "munilyProviders",
  vigilantCalls = "vigilantCalls",
  knowledgeCenter = "knowledgeCenter",
  accessControlMacroAdmin = 'accessControlMacroAdmin',
  accessControlMacroBusiness = 'accessControlMacroBusiness',
  securityCenterMacroAdmin = 'securityCenterMacroAdmin',
  securityCenterMacroBusiness = 'securityCenterMacroBusiness'
}

export let ROUTES:any = {};

ROUTES[mainFeatures.dashboard] = {
  path: '/app/dashboard',
  title: 'menu.home',
  icon: 'fa fa-home fa-2x',
  // icon2: 'https://munily-public-cdn.s3.amazonaws.com/side-menu/dashboard/house.svg',
  class: '',
  extralink: false,
  submenu: [],
} as RouteInfo;

ROUTES[mainFeatures.dashboardSuperAdmin] = { //**
  path: '/app/dashboard-admin',
  title: 'menu.home',
  icon: 'fa fa-home fa-2x',
  // icon2: 'https://munily-public-cdn.s3.amazonaws.com/side-menu/dashboard/house.svg',
  class: '',
  extralink: false,
  submenu: [],
} as RouteInfo;

ROUTES[mainFeatures.buildings] = { //**
  path: '/app/buildings',
  title: 'Edificios',
  icon: 'fa fa-city fa-2x',
  class: '',
  extralink: false,
  submenu: [],
} as RouteInfo;

ROUTES[mainFeatures.communities] = { //**
  path: '/app/communities',
  title: 'menu.communities',
  icon: 'fas fa-users fa-2x',
  class: '',
  extralink: false,
  submenu: [],
} as RouteInfo;

ROUTES[mainFeatures.usersSuperAdmin] = { //**
  path: '/app/user-administration',
  title: 'menu.administration',
  icon: 'fas fa-tv fa-2x',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.services] = { //**
  path: '/app/app-services-super',
  title: 'menu.phservices',
  icon: 'fas fa-tv fa-2x',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.releases] = { //**
  path: '/app/app-notice-munily-super',
  title: 'menu.releases',
  icon: 'fas fa-tv fa-2x',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.releases] = { //**
  path: '/app/app-notice-munily-super',
  title: 'menu.releases',
  icon: 'fas fa-tv fa-2x',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.accountStatements] = {
  path: '/app/onboarding/accounts-status',
  title: 'menu.accountStatements',
  icon: 'fas fa-chart-area fa-2x',
  icon2: '../../../assets/images/icons/side-menu/area-chart.svg',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.notices] = {
  path: '/app/noticesv2',
  title: 'menu.notices',
  icon: 'fa fa-envelope fa-2x',
  icon2: '../../../assets/images/icons/side-menu/message-text.svg',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.chats] = {
  path: '/app/messages',
  title: 'menu.chats',
  icon: 'fa fa-comments fa-2x',
  // icon2: 'https://munily-public-cdn.s3.amazonaws.com/side-menu/dashboard/chats.svg',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.reservations] = {
  path: '/app/reservations',
  title: 'menu.reservations',
  icon: 'fa fa-calendar-alt fa-2x',
  icon2: 'https://munily-public-cdn.s3.amazonaws.com/side-menu/dashboard/reservations.svg',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.visits] = {
  path: '/app/visits',
  title: 'menu.invitations',
  icon: 'fa fa-file-invoice fa-2x',
  icon2: '../../../assets/images/icons/side-menu/cost-estimate.svg',
  class: '',
  extralink: false,
  submenu: [],
} as RouteInfo;

ROUTES[mainFeatures.frequentAccess] = {
  path: '/app/frequent-access/list',
  title: 'menu.frequent-access',
  icon: 'fa fa-door-open fa-2x',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.users] = {
  path: '/app/tenants',
  title: 'menu.tenants',
  icon: '',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.registrations] = {
  path: '/app/registration',
  title: 'menu.preRegistration',
  icon: '',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.administration] = {
  path: '/app/administrative',
  title: 'menu.administrative',
  icon: '',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.areas] = {
  path: '/app/areas',
  title: 'menu.areas',
  icon: '',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.buildingsAdmin] = {
  path: '/app/buildings-admin',
  title: 'menu.buildings',
  icon: '',
  class: '',
  extralink: false,
  submenu: [],
} as RouteInfo;

ROUTES[mainFeatures.accessManagement] = {
  path: '/app/door-opening/access',
  title: 'Gestion de acceso',
  icon: '',
  class: '',
  extralink: false,
  submenu: [],
} as RouteInfo;

ROUTES[mainFeatures.securityCenters] = {
  path: '/app/door-opening/security',
  title: 'Centro de Seguridad',
  icon: '',
  class: '',
  extralink: false,
  submenu: [],
} as RouteInfo;

ROUTES[mainFeatures.pqrs] = {
  path: '/app/pqrs',
  title: 'menu.pqrs',
  icon2: 'https://munily-public-cdn.s3.amazonaws.com/side-menu/dashboard/mailbox.svg',
  class: '',
  extralink: false,
  submenu: [],
} as RouteInfo;

ROUTES[mainFeatures.administrativeDropdown] = {
  path: '',
  title: 'menu.administration',
  icon: 'fa fa-desktop fa-2x',
  icon2: 'https://munily-public-cdn.s3.amazonaws.com/side-menu/dashboard/screen-alt.svg',
  class: 'has-arrow',
  extralink: false,
  submenu: [
    ROUTES[mainFeatures.users],
    ROUTES[mainFeatures.registrations],
    ROUTES[mainFeatures.administration],
    ROUTES[mainFeatures.areas],
    ROUTES[mainFeatures.buildingsAdmin]
  ]
} as RouteInfo;

ROUTES[mainFeatures.doorOpening] = {
  path: '',
  title: 'Apertura de puertas',
  icon: 'fa fa-door-open fa-2x',
  class: 'has-arrow',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.munilyPay] = {
  path: '/app/munily-pay/accounts',
  title: 'Munily Pay',
  icon: 'fa fa-regular fa-money-bill',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;


ROUTES[mainFeatures.munilyPayTransactions] = {
  path: '/app/munily-pay/transactions',
  title: 'Munily Pay',
  icon: 'fa fa-regular fa-money-bill',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.doorOpeningAdmin] = {
  path: '/app/door-opening-admin/access',
  title: 'Apertura de puertas',
  icon: '',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.controlAccess] = {
  path: '',
  title: 'Control de acceso',
  icon: 'fa fa-door-open fa-2x',
  class: 'has-arrow',
  extralink: false,
  submenu: [
    ROUTES[mainFeatures.doorOpeningAdmin]
  ]
} as RouteInfo;

ROUTES[mainFeatures.munilyProviders] = {
  path: 'https://munily.com/proveedores/',
  title: 'munilyProviders.title',
  icon2: 'https://munily-public-cdn.s3.amazonaws.com/home/dashboard/provider-logo.svg',
  class: '',
  extralink: false,
  submenu: [],
  new: true,
  externalLink: true
} as RouteInfo;

ROUTES[mainFeatures.vigilantCalls] = {
  path: '/app/vigilant-calls',
  title: 'menu.calls',
  icon2: 'https://munily-public-cdn.s3.amazonaws.com/side-menu/dashboard/phone_in_talk.svg',
  class: '',
  extralink: false,
  submenu: [],
  new: false,
  externalLink: false
} as RouteInfo;

ROUTES[mainFeatures.knowledgeCenter] = {
  path: 'https://centrodeayuda.munily.com/es/administrador',
  title: 'menu.knowledgeCenter',
  icon: 'fa fa-compass fa-2x',
  class: '',
  extralink: false,
  submenu: [],
  new: true,
  externalLink: true
} as RouteInfo;

ROUTES[mainFeatures.accessControlMacroAdmin] = {
  path: '/app-community/door-opening-macro-admin/access',
  title: 'Gestión de accesos',
  icon: 'fa fa-door-open fa-2x',
  class: '',
  submenu: [],
  new: false,
  extralink: false,
  externalLink: false
} as RouteInfo;

ROUTES[mainFeatures.accessControlMacroBusiness] = {
  path: '/app/door-opening-business/access',
  title: 'Gestión de accesos',
  icon: 'fa fa-door-open fa-2x',
  class: '',
  submenu: [],
  new: false,
  extralink: false,
  externalLink: false
} as RouteInfo;

ROUTES[mainFeatures.securityCenterMacroAdmin] = {
  path: '/app-community/door-opening-macro-admin/security',
  title: 'Centro de seguridad',
  icon: 'fa fa-door-open fa-2x',
  class: '',
  submenu: [],
  new: false,
  extralink: false,
  externalLink: false
} as RouteInfo;

ROUTES[mainFeatures.securityCenterMacroBusiness] = {
  path: '/app/door-opening-business/security',
  title: 'Centro de seguridad',
  icon: 'fa fa-door-open fa-2x',
  class: '',
  submenu: [],
  new: false,
  extralink: false,
  externalLink: false
} as RouteInfo;

export const SUB_MENU_ITEMS = [
  { submenu: mainFeatures.securityCenters, main: mainFeatures.doorOpening},
  { submenu: mainFeatures.accessManagement, main: mainFeatures.doorOpening}
];



