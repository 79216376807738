import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { WebcamModule } from 'ngx-webcam';
import { httpLoaderFactory } from '../public/public.module';
//Material
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { InformativeModalComponent } from './modals/modal-informative/modal-informative';

//COMPONENTS
import { ModalBuildingComponent } from '../pages/buildings/modal-building/modal-building.component';
import { CommunicateModalComponent } from './superadmins-components/communicate-modal/communicate-modal.component';
import { AdministrativeModalComponent } from './administrative/administrative-modal/administrative-modal.component';
import { FilterCleanComponent } from './filter-clean/filter-clean.component';
import { StatusBuildingAdminModalComponent } from './home-components/status-building-admin-modal/status-building-admin-modal.component';
import { SuperadminsModalComponent } from './superadmins-components/superadmins-modal/superadmins-modal.component';
import { UpdateServiceComponent } from '../pages/components-superadmins/services-buildings/update-service/update-service.component';
//PIPE, DIRECTIVES & SERVICES
import { FilterAccountingUnitsPipe } from '../pipes/filterAccountingUnits.pipe';
import { FilterCategory } from '../pipes/filterCategory.pipe';
import { SearchNameService } from "../pipes/searchNameService.pipe";
import { BuildingService } from '../../app/services/building.service';
import { TokenInterceptor } from './../interceptor/token.interceptor';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { WebcamModalComponent } from './webcam-modal/webcam-modal.component';
import { MultisessionSidebarComponent } from './multisession-sidebar/multisession-sidebar.component';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { LogoutModalComponent } from './logout-modal/logout-modal.component';
// PIPES
import { FilterUserPipe } from '../pipes/filterUsers.pipe';
import { SetRowClass } from '../pipes/setRowClass';
import { AreaConfigurationModalComponent } from './area-configuration-modal/area-configuration-modal/area-configuration-modal.component';
import { SuccessModalComponent } from './superadmins-components/success-modal/success-modal.component';
import { WarningModalComponent } from './superadmins-components/warning-modal/warning-modal.component';


@NgModule({

  declarations: [DragAndDropDirective, FilterCleanComponent, SuperadminsModalComponent, UpdateServiceComponent, StatusBuildingAdminModalComponent, ModalBuildingComponent, CommunicateModalComponent, AdministrativeModalComponent, FilterCategory, FilterAccountingUnitsPipe, SearchNameService, WebcamModalComponent, MultisessionSidebarComponent, LoginModalComponent, LogoutModalComponent, FilterUserPipe, SetRowClass, AreaConfigurationModalComponent, SuccessModalComponent, WarningModalComponent, InformativeModalComponent],
  imports: [
    CommonModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    AgmCoreModule,
    NgbModule,
    MatSlideToggleModule,
    FormsModule,
    NgSelectModule,
    NgbTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    WebcamModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient, HttpClientModule],
      },
    }),
    MatDialogModule
  ],
  providers: [
    BuildingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  exports: [
    DragAndDropDirective,
    MatTableModule,
    MatPaginatorModule,
    AgmCoreModule,
    MatSortModule,
    MatIconModule,
    MatMenuModule,
    NgxIntlTelInputModule,
    FilterCleanComponent,
    SuperadminsModalComponent,
    ModalBuildingComponent,
    CommunicateModalComponent,
    UpdateServiceComponent,
    StatusBuildingAdminModalComponent,
    MatInputModule,
    MatSidenavModule,
    NgSelectModule,
    NgbTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatMenuModule,
    MatDatepickerModule,
    MatCardModule,
    AdministrativeModalComponent,
    FilterCategory,
    FilterAccountingUnitsPipe,
    SearchNameService,
    WebcamModule,
    WebcamModalComponent,
    MultisessionSidebarComponent,
    LogoutModalComponent,
    FilterUserPipe,
    SetRowClass,
    AreaConfigurationModalComponent
  ]
})
export class SharedModule { }
